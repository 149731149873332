<template>
<div>
  <Menu :menuTitle="title"/>
  <div class="v-table">
    
    <!-- 考试成绩 -->
    <el-table
        :data="list"
        :empty-text='"暂无数据"'
        style="width: 100%">

        <el-table-column prop="date" label="排行" width="120" >
            <template #default="scope">
              <img v-if="scope.$index == 0" :src="first"  class="icon-img"/>
              <img v-else-if="scope.$index == 1" :src="second" class="icon-img"/>
              <img v-else-if="scope.$index == 2" :src="third" class="icon-img"/>
              <div v-else class="bage">{{scope.$index + 1}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户名" width="260">
            <template #default="scope">
              <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
              <span style="vertical-align:super;margin-left:8px;">{{ scope.row.userName }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="score" label="陪练次数" width="160" >
          <template #default="scope">
            <span style="">{{ scope.row.trainCount }}</span>
            <span @click="goList(scope.row)" style="margin-left:10px;cursor: pointer;"><i class="el-icon-document lefBtn_icon"></i></span>
          </template>
        </el-table-column>
        <el-table-column label="平均成绩" width="160" >
          <template #default="scope">
            <span>{{ scope.row.avgScore }}</span>分
          </template>
        </el-table-column>
        <el-table-column  label="最高成绩" width="160" >
          <template #default="scope">
            <span>{{ scope.row.maxScore }}</span>分
          </template>
        </el-table-column>
        <el-table-column label="最新成绩" >
          <template #default="scope">
            <span style="color: #2D70F8">{{ scope.row.lastScore }}分</span>
          </template>
        </el-table-column>
    </el-table>
  </div>
    <!-- {{list}} -->
  
</div> 
</template>

<script>
// import firstIcon from '@assets/images/avatar.png';
import Menu from '@/components/Menu'
export default {
  components:{
    Menu,
 },
  data() {
    return {
      first:require("@/assets/images/icon_first.png"),
      second:require("@/assets/images/icon_second.png"),
      third:require("@/assets/images/icon_third.png"),
      selectedIndex:0,
      list:[]
    };
  },
  watch: {
  },
  methods: {
    getInfoList(){
      this.$http.post('/corporate/university/grade/ai/aiScoreList',{
        "aiId": this.$route.query.aiId,
        "gradeConfId": this.$route.query.gradeConfId,
        "gradeId":this.$route.query.gradeId
      }).then((res)=>{
        // console.log(res)
        if(res.data.code == 0){
          this.list = res.data.data
        }
      }).catch((res)=>{
        this.$message.warn(res.data.msg)
      })
    },
    goList(val){
      // console.log(val)
      this.$router.push({
        path: '/teacStuPracticePartnerList',
        query:{
          "aiId": val.aiId,
          "gradeId": this.$route.query.gradeId,
          "trainCount": val.trainCount0,
          "userId": val.userId
        }
      })
    },
  },
  created() {
    this.title = localStorage.getItem('testName')
    this.getInfoList()
    // this.gradeConfId = this.$route.query.id || JSON.parse(sessionStorage.getItem('gradeConfInfo')).id
    // this.dayList = data.data
  }
}
</script>

<style lang="scss" scope>
.v-table {
  padding-top: 120px;
  background: #fff;
  width: 1200px;
  margin: auto;
  .el-table{
    th{
      background-color: #fafafa;
      
      .cell{
        color: #999;
      }
    }
    .cell{
        font-size: 16px;
        padding-left: 31px;
        color: #333;
    }
    td{
        height: 80px;
        .cell{
          div{
            font-size: 16px;
            color: #333;
          }
        }
    }
    td:nth-of-type(1){
      border-left: 1px solid #DDDDDD;
    }
    td:last-of-type{
      border-right: 1px solid #DDDDDD;
    }
  }
  .icon-img{
    width: 36px;
    height: 45px;
    display: block;
  }
  .bage{
    width: 32px;
    height: 32px;
    border-radius: 32px;
    text-align: center;
    line-height: 32px;
    color: #999;
    background-color: #EDECED;
  }
}
</style>