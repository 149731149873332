<template>
  <UserInfoMenu/>
</template>

<script>
import { reactive } from "vue";
import UserInfoMenu from '@/components/UserInfoMenu'
export default {
  setup() {
    const classInfo = reactive({
        arrangerTeacher: 0,
        endGradeTime: "2020.10.28 08:00",
        gradeConfId: 0,
        gradeConfName: "新生班",
        gradeName: "2020年第8期客服",
        id: 0,
        inClass: 0,
        masterId: 0,
        masterName: "田世宣",
        openGradeTime: "2020.10.26 08:00",
        peopleNum: 0,
        stuLists: [],
      });
    return {
        classInfo
    }
 },
  components: { UserInfoMenu },
};
</script>

<style>
</style>