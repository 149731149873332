<template>
<div class="camera_outer" style="display:none">
    <canvas
      style="display: none"
      id="canvasCamera"
      :width="videoWidth"
      :height="videoHeight"
    ></canvas>
    <div v-if="imgSrc" class="img_bg_camera">
      <img :src="imgSrc" alt="" class="tx_img" />
    </div>
    <button @click="setImage()">拍照</button>
  </div>
  <audio autoplay style="display: none" :src="xcSDKParams.srcUrl"></audio>
  <div class="practice__header">
    {{ videoParams.title }}
    <span class="xc__close" @click="closePage"
      ><i class="el-icon-close"></i
    ></span>
  </div>
  <div class="practice__content">
    <div class="practice__info">
      <div class="practice__video_view bg__white">
        <div class="practice__video_avator">
          <img
            class="avator"
            :src="
              videoParams.userInfo.headUrl ||
              require('../../assets/images/header.png')
            "
            alt=""
          />
        </div>
        <div class="practice__video_title">
          {{ videoParams.userInfo.realName }}
        </div>
        <!-- <div class="practice__video_sub">
          第{{ videoParams.userInfo.number }}位学员
        </div> -->
        <div class="practice__video_sub">{{ videoParams.gradeName }}</div>
        <div class="practice__video_time">
          {{
            !mainParams.startTest && mainParams.hasResultScore
              ? "用时"
              : "计时"
          }}：<span class="practice__time_cor">{{
            videoParams.timeCounter
          }}</span>
        </div>
      </div>
      <div class="practice__video_rules bg__white">
        <div class="practice__video_title">陪练规则</div>
        <div class="practice__video_item">1、按照演练剧本进行陪练</div>
        <div class="practice__video_item">
          2、准确性及流畅性得分<span class="cor__primary">80</span
          >分以上则陪练合格
        </div>
        <div class="practice__video_item">
          3、建议时间<span class="cor__primary">30</span>分钟
        </div>
      </div>
    </div>
    <div class="practice__main bg__white">

      <div v-if="this.$route.query.isList == 'isList' || resultStatus" ref="scrollTar" class="practice__main_view beautibar">
        <template
          v-for="(item, index) in coachChatLogList"
          :key="'msg_' + index"
        >
          
          
          <div v-if="item.deductScore > 0" class="deduct_points_box">
            <span class="line"></span>
            <span class="deduct_points">-{{item.deductScore}}</span>
          </div>
          <div v-if="item.question" class="message__self message__self_box message__line">
            <div class="speak_box">
              <div v-if="JSON.parse(item.extInfo).dbs_jc && JSON.parse(item.extInfo).dbs_jc.grass" class="grass_speak_box">
                <span class="grass_speak" v-for="(itemGrass, indexGrass) in JSON.parse(item.extInfo).dbs_jc.grass.split(',')" :key="indexGrass">
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '亲和草'" :style="{background: grassBgColor[0]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '为他着想草'" :style="{background: grassBgColor[1]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '危机草'" :style="{background: grassBgColor[2]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '激将草'" :style="{background: grassBgColor[3]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '洞察草'" :style="{background: grassBgColor[4]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '专业草'" :style="{background: grassBgColor[5]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '假设获得草'" :style="{background: grassBgColor[6]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '信任草'" :style="{background: grassBgColor[7]}">{{itemGrass.split('&').join(" ")}}</span>
                  <span class="grasses" v-if="itemGrass.split('&')[0] == '价值草'" :style="{background: grassBgColor[8]}">{{itemGrass.split('&').join(" ")}}</span>
                </span>
              </div>
              <div class="message__detail" v-html="item.question"></div>
              <img
                class="message__avator"
                :src="require('../../assets/images/header.png')"
                alt=""
              />
            </div>
            <div class="warn_msg_box">
              <div class="warn_msg" v-if="item.sensitiveWord">
                <img :src="require('../../assets/images/icon_warn.png')" class="icon_warn">  {{item.sensitiveWord}}
              </div>
              <div class="reference">
                <span class="refernce_txt">参考</span> {{item.standQuestion}}
              </div>
            </div>
            <div class="intents_box">
              <div class="intents_tiem" v-for="(itemChild, indexChild) in JSON.parse(item.extInfo).intents" :key="indexChild">
                <span class="intents_list" :class="{'hit': itemChild.hit}">{{itemChild.name}}</span>
              </div>
            </div>
          </div>

          <div v-if="item.answers && item.answers.length > 0">
            <div class="message__others message__line" v-for="(itemS, index) in item.answers"
            :key="'msg_' + index">
              <img
                v-if="itemS.answer !== '#####'"
                class="message__avator"
                :src="require('../../assets/images/header.png')"
                alt=""
              />
              <div v-if="itemS.answer !== '#####'" class="message__detail" v-html="itemS.answer"></div>
            </div>
          </div>

        </template>
      </div>
      <div v-else>
        <div v-if="trainType == '0'" ref="scrollTar" class="practice__main_view beautibar">
          <template
            v-if="greetings"
          >
            <div v-for="(item, index) in greetings"
            :key="'msg_' + index">
              <div v-if="item.reply" class="message__others message__line">
                <img
                  v-if="item.reply !== '#####'"
                  class="message__avator"
                  :src="require('../../assets/images/header.png')"
                  alt=""
                />
                <!-- <div class="message__detail">{{item}}1</div> -->
                <div v-if="item.reply !== '#####'" class="message__detail" v-html="item.reply"></div>
                <!-- <audio 
                  @play="onPlay"
                  @ended="overAudio"
                  id="audio_player" 
                  :autoplay = controlVideoS
                  style="display:none;">
                  <source :src="hostHttp + item.tts" >
                </audio> -->
              </div>
            </div>
          </template>
          <template
            v-for="(item, index) in chatData"
            :key="'msg_' + index"
          >
            <div class="message__self message__line">
              <div class="message__detail" v-html="item.content"></div>
              <!-- <div class="message__detail" v-html="chatData[index].text"></div> -->
              <img
                class="message__avator"
                :src="require('../../assets/images/header.png')"
                alt=""
              />
            </div>
            <div v-if="item.reply" class="message__others message__line">
              <!-- <div class="message__detail">{{item}}1</div> -->
              <div v-if="item.reply != 'replys'" style="display: flex;">
                <img
                  v-if="item.reply !== '#####'"
                  class="message__avator"
                  :src="require('../../assets/images/header.png')"
                  alt=""
                />
                <div v-if="item.reply !== '#####'" class="message__detail" v-html="item.reply"></div>
              </div>
              <div v-else>
                <div 
                  style="display: flex;margin-bottom: 10px;"
                  v-for="(itemRe, indexRe) in item.answers"
                  :key="'msg1_' + indexRe">
                  <img
                    v-if="itemRe.answer !== '#####'"
                    class="message__avator"
                    :src="require('../../assets/images/header.png')"
                    alt=""
                  />
                  <div v-if="itemRe.answer !== '#####'" class="message__detail" v-html="itemRe.answer"></div>
                </div>
              </div>
              <audio 
                v-if="item.reply != 'replys'"
                @play="onPlay"
                @ended="overAudio"
                id="audio_player" 
                :autoplay = controlVideoS
                style="display:none;">
                <source :src="hostHttp + item.tts" >
              </audio>
            </div>
          </template>
        </div>
        <div v-else ref="scrollTar" class="practice__main_view beautibar">
          <template
            v-for="(item, index) in xcSDKParams.msgList"
            :key="'msg_' + index"
          >
            <div v-if="item.isRobot" class="message__others message__line">
              <img
                v-if="item.text !== '#####'"
                class="message__avator"
                :src="require('../../assets/images/header.png')"
                alt=""
              />
              <!-- <div class="message__detail">{{item}}1</div> -->
              <div v-if="item.text !== '#####'" class="message__detail" v-html="item.text"></div>
              <audio :autoplay = controlVideoS style="display:none;">
                <source v-if="item.tts" :src="hostHttp + item.tts" >
              </audio>
            </div>
            <div v-else class="message__self message__line">
              <div class="message__detail" v-html="item.text"></div>
              <img
                class="message__avator"
                :src="require('../../assets/images/header.png')"
                alt=""
              />
            </div>
          </template>
        </div>
      </div>
      <span
        class="s_button"
        @click="startTest"
        v-if="!mainParams.startTest && !hasbeenTested"
        >开始</span
      >
      <span v-if="!hasbeenTested" class="control_video_c" @click="controlVideo">
        <img v-if="controlVideoS" :src="require('../../assets/images/practice_icon_voice.png')"/>
        <img v-else :src="require('../../assets/images/practice_icon_mute.png')"/>
      </span>
      <span
        class="s_button del_styu"
        @click="endTest"
        v-if="mainParams.startTest && !hasbeenTested"
        >结束</span
      >
      <div class="message__input" v-show="mainParams.startTest">
        <textarea 
          v-if="this.trainType == '1'"
          placeholder="回车键发送陪练内容"
          maxlength="2000"
          class="message__input_tar beautibar"
          @keypress="textKeyborad($event)"
          v-model="xcSDKParams.inputVal"
        ></textarea>
        <span :class="{'realtime_voice':trainType == '0'}" class="m__button" @click="startRecode">
          <i class="mirco__icon el-icon-microphone"></i
          >{{ !videoParams.recordState ? "开始说话" : "结束说话" }}</span>
        <span v-if="this.trainType == '1' && changeMind" :class="{'realtime_voice':trainType == '0'}" class="m__button change_mind" @click.stop>
          <i class="mirco__icon el-icon-microphone"></i
          >转译中...</span>
      </div>
      <div class="message__input" v-if="(!mainParams.startTest) && (hasbeenTested) && (this.$route.query.isList != 'isList')">
        <span :class="{'realtime_voice':trainType == '0'}" class="m__button" @click="practiceAgain">
          <i class="mirco__icon el-icon-microphone"></i>重新练习</span>
      </div>
    </div>
    <div v-if="mainParams.hasResultScore" class="practice__result">
      <div class="practice__result_item bg__white">
        <div class="practice__result_title">本次得分</div>
        <div v-if="compareStatus == '2'" class="lastScore">比上次<img src="../../assets/images/practice_upper.png">{{compareScore}}分</div>
        <div v-if="compareStatus == '0'" class="lastScore">比上次<img src="../../assets/images/practice_lower.png">{{compareScore}}分</div>
        <div v-if="compareStatus == '1'" class="lastScore">与上次相等</div>
        <div class="practice__result_content">
          <div class="practice__result_detail">
            <span class="size--inner"
              >{{ standScoer}}<span style="font-size: 16px">分</span>
            </span>
          </div>
        </div>
      </div>
      <div class="practice__result_item bg__white">
        <div class="practice__result_title">能力模型</div>
        <div class="practice__result_content">
          <div class="echart_tar" ref="echart_tar"></div>
        </div>
      </div>
      <div class="practice__result_item bg__white">
        <div class="practice__result_title">分析数据</div>
        <div class="practice__result_content">
          <ul class="practice__result_list">
            <li class="practice__result_listitem">
              学员情绪：
              <span v-if="analyseEmotion_1 && analyseEmotion_1 != 0" class="col__font--default">消极:{{ analyseEmotion_1 }}次  </span>
              <span v-if="analyseEmotion_2 && analyseEmotion_2 != 0" class="col__font--default">中性:{{ analyseEmotion_2 }}次  </span>
              <span v-if="analyseEmotion_3 && analyseEmotion_3 != 0" class="col__font--default">积极:{{ analyseEmotion_3 }}次  </span>
            </li>
            <li class="practice__result_listitem">
              语速判断：<span class="col__font--default">
                <span v-if="fastCount && fastCount != 0">正常{{ fastCount }}次  </span>
                <span v-if="slowCount && slowCount != 0">缓慢{{ slowCount }}次  </span>
                <span v-if="normalCount && normalCount != 0">较快{{ normalCount }}次  </span>
                </span>
            </li>
            <!-- <li class="practice__result_listitem">
              流 畅  性：<span class="col__font--default">{{ analyseFluency }}</span>
            </li> -->
            <li class="practice__result_listitem">
              违规次数：
              <span class="col__font--default infractions_num">{{ analyseSensitive.length }}次</span>
              <!-- <span class="infractions_see">查看</span> -->
            </li>
            <li class="practice__result_listitem">
              <!-- {{JSON.parse(resultParams.analyse.sensitive)}} -->
              敏 感 词：<span class="con_sensitive" v-if="analyseSensitive && analyseSensitive.length > 0">{{analyseSensitive.join()}}<span class="see_sensitive" @click="seeSensitive">查看</span></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="practice__result_item bg__white">
        <div class="practice__result_title">博弈情况</div>
        <div class="grass_box">
          <div class="grass_list" v-for="(item, index) in gameInfoList" :key="index">
            <div class="part1">
              <span class="name">{{item.name}}</span>
              <span class="status">{{item.level.level1}}/{{item.level.level2}}/{{item.level.level3}}/{{item.level.level4}}</span>
            </div>
            <div class="part2">
              <div class="part2_b_box">
                <span class="part2_b part2_bb" :style="{width: item.level.level1 * 6.3 + 'px', background: grassBgColor[index]}"></span>
                <span class="part2_b"></span>
              </div>
              <div class="part2_b_box">
                <span class="part2_b part2_bb" :style="{width: item.level.level2 * 6.3 + 'px', background: grassBgColor[index]}"></span>
                <span class="part2_b"></span>
              </div>
              <div class="part2_b_box">
                <span class="part2_b part2_bb" :style="{width: item.level.level3 * 6.3 + 'px', background: grassBgColor[index]}"></span>
                <span class="part2_b"></span>
              </div>
              <div class="part2_b_box">
                <span class="part2_b part2_bb" :style="{width: item.level.level4 * 6.3 + 'px', background: grassBgColor[index]}"></span>
                <span class="part2_b"></span>
              </div>
            </div>
            <div class="part3">
              <span v-for="(part_b, inde) in grassBgNum" :key="inde">L{{inde + 1}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="practice__result bg__white">
      <video
        id="videoCamera"
        class="practice__result_stream"
        v-stream="xcSDKParams.streamOptions"
        muted
      ></video>
      <ul class="practice__result_list">
        <li class="practice__result_listitem list__item">
          学员情绪：<span class="col__font--default">{{
            emotionState[xcSDKParams.emotion] || "--"
          }}</span>
        </li>
        <li class="practice__result_listitem list__item">
          语速检测：<span class="col__font--default">{{
            xcSDKParams.speed ? xcSDKParams.speed + "字/分钟" : "--"
          }}</span>
        </li>
        <!-- <li class="practice__result_listitem list__item">
          语速判断：<span class="col__font--default">2次卡顿 3次抢先</span>
        </li> -->
        <li class="practice__result_listitem list__item_d">
          敏 感 词：<span>{{
            xcSDKParams.sensitiveWords ? xcSDKParams.sensitiveWords : "--"
          }}</span>
        </li>
      </ul>
    </div>
    <Violation :violationData="violationData" :clickStatus="clickStatus" @itemclick="violationClose" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { reactive, readonly, ref, toRef } from "vue";
import { TimestampToDate } from "../../utils/utils";
import axios from "axios";
import http from "@/utils/request";
import Recorder from './_js/recorder';
import Violation from "./violation.vue"
import {StartStudy,EndStudy} from '@/server/details'
let classQuery = null;
const WS_STATE = { OPEN: 1 };

export default {
  components: {
    Violation
  },
  setup(props, context) {
    const $http = axios.create({
      baseURL: "/api",
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("_token")}`,
      },
    });

    const chartsOpts = (arr) => ({
      tooltip: {
        trigger: "axis",
      },
      radar: [
        {
          indicator: [
            { text: "话术熟练度", max: 10 },
            { text: "亲和力", max: 10 },
            { text: "逻辑性", max: 10 },
            { text: "技巧能力", max: 10 },
            { text: "反应力", max: 10 },
          ],
        },
      ],
      series: [
        {
          type: "radar",
          tooltip: {
            trigger: "item",
          },
          labelLayout(params) {
            return {
              x: params.rect.x + 10,
              y: params.rect.y + params.rect.height / 2,
              verticalAlign: "middle",
              align: "left",
            };
          },
          areaStyle: {},
          data: [
            {
              value: arr,
              name: "能力模型",
            },
          ],
        },
      ],
    });

    const initCharts = ($el, abilityModel) => {
      const myCahrts = echarts.init($el);
      myCahrts.setOption(
        chartsOpts(
          [
            "practised",
            "affinity",
            "logic",
            "capacity",
            "reagency",
          ].map((key) => abilityModel[key])
        )
      );
    };

    const videoParams = reactive({
      title: "",
      gradeName: "",
      userInfo: {
        name: "",
        number: 0,
        info: "",
      },
      recordState: false,
      timeCounter: "00:00:00",
      timerIns: null,
    });

    const resultParams = reactive({
      analyse: {},
    });

    const mainParams = reactive({
      startTest: false,
      hasResultScore: false,
    });

    // const host = readonly("ws://119.3.177.250:31480");
    
    // 2022-4-11之前地址
    // const host = readonly("ws://119.3.177.250:32004");
    // const systemId = readonly("douboshi");
    // const hostHttp = readonly("http://119.3.177.250:31480/coach/");
    // const hostHttpImage = readonly("http://119.3.177.250:32004");

    // 2022-4-11更换新地址-pre https(31443)
    // const host = readonly("ws://191.3.177.250:31480/coach");
    // const systemId = readonly("douboshi");
    // const hostHttp = readonly("http://191.3.177.250:31480/coach/");

    // 2022-4-11更换新地址-prod https(31443)
    const host = readonly("ws://121.36.97.186:31480/coach");
    const systemId = readonly("douboshi");
    const hostHttp = readonly("http://121.36.97.186:31480/coach/");



    const _xcSDKParams = {
      Server: null,
      srcUrl: null,
      inputVal: "",
      msgList: [],
      callId: 0,
      resultId: 0,
      userId: null,
      type: null,
      emotion: "",
      speed: "",
      sensitiveWords: "",
      sensitiveWordList: [],
      recordData: {},
      streamOptions: {
        video: true,
        audio: true,
        videoOpts: { monitoring: false, dely: 2000 },
      },
      info: {},
    };

    const socketQuery = {
      id: 0, // 课程ID
      name: "", // 课程名称
    };

    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

    const xcSDKParams = reactive(deepCopy(_xcSDKParams));

    const reset_xcSDKParams = () => {
      let omap = deepCopy(_xcSDKParams);
      Object.keys(omap).forEach(
        (key) => key !== "Server" && (xcSDKParams[key] = omap[key])
      );
    };

    const LoginPageInfo = reactive({});

    const grassBgNum = reactive([0, 0, 0, 0])
    const grassBgColor = reactive(['#66B92E', '#8F76E4', '#F04B4A', '#29ABE2', '#ED1F7A', '#FCAE3B', '#BFC545', '#9E015D', '#263042']);
    const grassSpeak = reactive([])

    return {
      videoParams,
      mainParams,
      xcSDKParams,
      resultParams,
      LoginPageInfo,
      socketQuery,
      host,
      systemId,
      hostHttp,
      grassBgNum,
      grassBgColor,
      grassSpeak,
      initCharts,
      reset_xcSDKParams,
    };
  },
  data: () => ({
    hasbeenTested: false,
    loadingIns: null,
    sensitive: ['1', '2'],
    compareScore: '',
    compareStatus: '',
    labelList: [],
    logId: '',
    genID: '',
    file2: '',
    MediaStreamTrackArr: null,
    videoWidth: 120,
    videoHeight: 100,
    imgSrc: "",
    thisCancas: null,
    thisContext: null,
    thisVideo: null,
    startRecodeStatus: false,
    grass: '',
    emotionState: {
      NEUTRAL: "平静",
      ANGRY: "生气",
      CONFUSED: "困惑",
      CONTEMPT: "鄙视",
      DISGUST: "恶心",
      FEAR: "害怕",
      HAPPY: "高兴",
      SAD: "悲伤",
      SURPRISE: "惊讶",
      NOFACE: '无脸',
      FATIGUE: '疲劳',
      HAPPINESS: '幸福',
      ANGER: '愤怒',
      SADNESS: '悲伤',
      ANXIETY: '焦虑',
      CONFUSION: '混乱',
      UNCONCERNED: '漠不关心的',
      AFRAID: '害怕的',
      BORING: '没趣的',
      EMBARRASSED: '尴尬'
    },
    trainType: '1',
    audioContext: null,
    wSocket: null,
    toggleFlag: false, // 不知道啥FLAG
    complexScore: 0,
    standScoer: 0,
    analyseEmotion_1: '',
    analyseEmotion_2: '',
    analyseEmotion_3: '',
    analyseSpeed: '',
    fastCount: '',
    normalCount: '',
    slowCount: '',
    analyseFluency: '',
    analyseViolation: 0,
    analyseSensitive: '',
    gameInfoList: [],
    coachChatLogList: [],
    resultStatus: false,
    clickStatus: false,
    violationData: [],
    counter: 0,
    changeMind: false,
    controlVideoS: false,
    timer: null,
    asrTimeF: null,
    asrTime: 0,
    limeNum: 0, // 控制开始按钮不会重复链接ws
    // 竹间定义
      emotionWs: null,
      // -----气泡 知识搜索
      isShowNotice: false,
      lastWord: "",
      knowPopList: [],
      dialogVisible: false,
      chatData: [],
      sensitiveWord: { wordStr: "", sensitiveTip: "" },
      customerEmotion: { customerEmotion: "", customerEmotionTip: "" },
      speechRate: "",
      stealWord: { stealWord: "", stealWordTip: "" },
      agentEmotion: { agentEmotion: "", agentEmotionTip: "" },
      emotionHistory: [],
      interval: null,
      currentDirection: 3, // 当前讲话人
      lastSentenceEndFlag: 1, // 新气泡语句
      customerPhone: "",
      wSocket: null,
      srcUrl: null,
      media: null,
      mediaStreamTrack: null, // 麦克风
      custoemotion: "",
      agentemotion: "",
      sentitiveWords: "",
      score: "",
      timerNum: 0,
      popContent: "",
      assistantText: "",
      emotionSocre: 0, // 情绪分数
      question: '', // 坐席问题
      speed: 0, // 说话速度
      speedScore: 0, // 说话分数
      emotionEnum: '', // 情绪
      audioContext: null,
      parah: '', // 一段话
      // typeValue: '',
      isclosewebsocket: false,
      resultType: "",
      detailgroup: [],
      chatDataDetails: [],
      conbinArr: [], // 整合语句数据
      takephotoErrorTotal: 0,
      // requireLimit: '',
      colorstyle: { color: "black" },
      isServerTalk: undefined,
      callId: null,
      istalking: true,
      isphotoAnalyseDone: true, // 用来控制情绪分析接口是否完成，完成后进行下一次调用
      faceError: false,
      waitasr: true, // 控制asr转译完才可以继续点击
      beginTime: 0, // 开始时间
      notOperatedTimes: 0, // 未操作时长
      notOperatedTimesSetInterObj: null, // 未操作倒计时对象
      countDownInterval: null, // 倒计时对象
      countDownTime: "5分0秒", // 倒计时时间
      closeExam: "",
      status: 'START', // 状态
      sensWord: '', // 敏感词
      cutText: '',
      realInterval: null,
      phCount: 0,
      phInterval: null,
      backNum: 0,
      greetings: [] // 问候语
  }),
  mounted() {
    // this.requestUserMedia(this.startRecordingNew);
    const that = this;
    if (!this.notHistoryShow) {
      this.$forceUpdate();
    } else {
      // -------对话页面  对话窗口自适应--------- //
      // 获取原窗口的高度
      const originalHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      window.onresize = () => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          // 当软键盘弹起，在此处操作
          that.scrollToBottom();
        } else {
          // 当软键盘收起，在此处操作
          that.scrollToBottom();
        }
      };
      const vid = document.getElementById("myAudio");
      // 录音开始播放后，结束5分钟计时
      vid.oncanplay = () => {
        // console.log("audio播放开始");
        this.endCountDown();
      };
      // 录音开始播放后，开始5分钟计时
      vid.onended = () => {
        // console.log("audio播放结束");
        this.startCountDown();
      };
    }
  },
  created() {
    // 开始学习
    StartStudy({
      "bussId": this.$route.query.id,
      "bussType": 9,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
      "gradeConfId": this.$route.query.gradeConfId,
      "gradeId": this.$route.query.gradeId,//班级id	
      // "studyTime": 0
    }).then(res=>{
      if(res.data.code === 0){
        //this.details = res.data.data
      }
    })
    this.trainType = this.$route.query.trainType
    if(this.$route.query.isList == 'isList') {
      this.getResultList();
      this.mainParams.startTest = false;
      this.mainParams.hasResultScore = true;
      this.hasbeenTested = true;
    } else {
      this.activePageMethod();
      window.onbeforeunload = () => this.xcSDKParams.Server?.closeConnect();

      window.AudioContext =
      window.AudioContext ||
      window.webkitAudioContext ||
      window.mozAudioContext;
    this.media =
      navigator.mediaDevices.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    }
  },
  watch: {
    toggleFlag() {
      if (this.toggleFlag) {
        this.requestUserMedia(this.startRecordingNew);
      } else {
        // console.log("点击了结束");
        this.closeExam = "结束了";
        this.srcUrl = null;
        // 结束判断 人是否说话
      }
    },
  },
  methods: {
    onPlay() {
      this.stopRecordingNew();
    },
    overAudio() {
      setTimeout(this.audioRecorder.record(), 200);
    },
    controlVideo() {
      if (this.controlVideoS) {
        this.controlVideoS = false
        this.$message({
          message: '已关闭语音播报',
          type: 'warning'
        });
      } else {
        this.controlVideoS = true
        this.$message({
          message: '已开启语音播报',
          type: 'success'
        });
      }
    },
    seeSensitive() {
      if(this.violationData.length > 0) {
        this.clickStatus = true
      } else {
        this.dialog()
      }
    },
    violationClose() {
      this.clickStatus = false
    },
    dialog() {
      const datas = {
        'logId': this.logId,
      }
      http.post("/corporate/university/ai/train/user/chat/dialog", datas).then((res) => {
        this.violationData = res.data.data
        this.clickStatus = true
      }).catch(() => {})
    },
    labels() {
      const datas = {
        "courseId": this.$route.query.courseId,
        "publishId": this.$route.query.zjId
      }
      http.post("/corporate/university/ai/train/practice/config/label", datas).then((res) => {
        this.labelList = res.data.data
        this.zjLog()
      }).catch(() => {})
    },
    zjLog(){
      const url = '/corporate/university/ai/train/practice/log'
      const labelsData = JSON.parse(sessionStorage.getItem("labelsData"))
      const datas ={
        'courseId': this.$route.query.courseId,
        'difficulty': 1,
        'label': labelsData || this.labelList,
        'publishId': this.$route.query.zjId,
        'userId': JSON.parse(sessionStorage.getItem("zjloginInfo")).id
      };
      const header ={
        "tenantId": JSON.parse(sessionStorage.getItem("zjloginInfo")).tenantId,
        "token": sessionStorage.getItem("_token"),
      };

      this.$http({
        method: "POST",
        url: url,
        data: datas,
        headers: header
      }).then((res) => {
        this.logId = res.data.data.id
        this.xcSDKParams.Server.setLogId(this.logId);
        this.zjChat('', 'START')
      }).catch(() => {})
    },
    zjChat(question, status, dataBlob, counter){
      const url = '/corporate/university/ai/train/user/chat'
      // 开始、结束、本文
      const data1 ={
        'examType': 'TEST',
        'logId': this.logId,
        'publishId': this.$route.query.zjId,
        'question': question,
        'type': 'TXT',
        'status': status
      };
      // 单句
      const data2 ={
        'publishId': this.$route.query.zjId,
        'examType': 'TEST',
        'logId': this.logId,
        'sensitiveWord': '', // 敏感词，用逗号分割
        'question': '',
        'speed': 0,
        'speedScore': 0,
        'status': status,
        'type': 'VOICE',
        'emotiFile': this.file2,
        'file': dataBlob,
        'ttsStatus': 'true',
        'asrTime': this.asrTime
      };
      // 实时
      const data3 ={
        'ttsStatus': 'true',
        'emotionSocre': '70',
        'examType': 'TEST',
        'logId': this.logId,
        'sensitiveWord': '',
        'question': question.paragraph,
        'speed': 0,
        'speedScore': 0,
        'status': status,
        'type': 'VOICE',
        'emotionEnum': 'NEUTRAL',
        'emotiFIle': this.file2,
        'emotionType': question.emotionType
      };
      
      var datas = {}
      if (this.trainType == 0) {
        datas = data3
      } else {
        if(this.startRecodeStatus) {
          if(status == 'END') {
            datas = data1
          } else {
            datas = data2
          }
        } else {
          datas = data1
        }
      }
      const formData = new FormData();
      Object.keys(datas).forEach((key) => {
        formData.append(key, datas[key]);
      });

      const header ={
        "tenantId": JSON.parse(sessionStorage.getItem("zjloginInfo")).tenantId,
        "token": sessionStorage.getItem("_token"),
        "Content-Type": 'application/json'
      };

      this.$http({
        method: "POST",
        url: url,
        data: formData,
        headers: header
      }).then((res) => {
        if (status == "END") {
          this.getResultList()
          return
          // this.xcSDKParams.Server.getResult();
        }
        if (res.data.data.status == 'END') {
          this.loadingIns = this.$loading({
            fullscreen: true,
            text: "正在获取结果...",
            background: "rgba(255,255,255, .6)",
          });
          this.getResultList()
          this.mainParams.startTest = false;
          this.mainParams.hasResultScore = true;
          if (this.trainType == '0') {
            this.stopRecordingNew();
            this.wSocket.onclose();
          }
          clearInterval(this.realInterval);
          this.hasbeenTested = true;
          this.stopTimeCounter();
          return
        }
        if(this.trainType == '0') {
          if(res.data.data.standQuestion) {
            // console.log('hhh')
          } else {
            for (let i = 0; i < res.data.data.answers.length; i++) {
              this.greetings.push({
                reply:  res.data.data.answers[i].answer,
              });
            }
          }
          this.xcSDKParams.emotion = question.emotionType
          if (res.data.data.answers.length == 1) {
            this.chatData[counter - 1].reply = res.data.data.answers[0].answer
            this.chatData[counter - 1].tts = res.data.data.answers[0].ttsPath
          } else {
            this.chatData[counter - 1].reply = 'replys'
            this.chatData[counter - 1].answers = res.data.data.answers
          }
          if (res.data.data.question) {
            this.getEmotionAndGrass(res.data.data.question)
          }
        } else {
          this.changeMind = false
          this.xcSDKParams.speed = res.data.data.speed
          this.xcSDKParams.emotion = res.data.data.emotion
          this.xcSDKParams.sensitiveWords = res.data.data.sensitiveWord
          if(this.startRecodeStatus) {
            this.xcSDKParams.msgList.push({
              isRobot: false,
              text: res.data.data.question,
            });
          }
          if (res.data.data.standQuestion) {
            for (let i = 0; i < res.data.data.answers.length; i++) {
              this.xcSDKParams.msgList.push({
                isRobot: true,
                text: res.data.data.answers[i].answer,
                tts: res.data.data.answers[i].ttsPath,
              });
            }

            if (res.data.data.question) {
              this.getEmotionAndGrass(res.data.data.question)
            }
          } else {
            for (let i = 0; i < res.data.data.answers.length; i++) {
              this.xcSDKParams.msgList.push({
                isRobot: true,
                text: res.data.data.answers[i].answer,
              });
            }
          }
        }
        this.bottomState();
      }).catch((e) => { 
        this.loadingIns.close();
        if (!e) {
          this.$message({
            message: '转意失败, 请重新录入。',
            type: 'warning'
          });
          this.changeMind = false
        }
       })
    },
    getEmotionAndGrass(question) {
      const url = '/corporate/university/ai/train/result/getEmotionAndGrass'
      const datas ={
        'callId': this.genID,
        'text': question,
        'type': 1
      };
      const header ={
        "tenantId": JSON.parse(sessionStorage.getItem("zjloginInfo")).tenantId,
        "token": sessionStorage.getItem("_token"),
      };

      this.$http({
        method: "POST",
        url: url,
        data: datas,
        headers: header
      }).then((res) => {
        // this.logId = res.data.data.id
        // this.grass = res.data.data.grass
      }).catch(() => {})
    },
    getImageEmotion(file2) {
      const url = '/corporate/university/ai/train/image'
      const datas ={
        'file': file2 || this.file2,
        'sessionId': 'dbs_' + this.genID,
        'systemId': this.systemId
      };
      const formData = new FormData();
      Object.keys(datas).forEach((key) => {
        formData.append(key, datas[key]);
      });
      const header ={
        "tenantId": JSON.parse(sessionStorage.getItem("zjloginInfo")).tenantId,
        "token": sessionStorage.getItem("_token"),
      };

      this.$http({
        method: "POST",
        url: url,
        data: formData,
        headers: header
      }).then((res) => {
        // console.log(res)
        // this.logId = res.data.data.id
        // this.grass = res.data.data.grass
      }).catch(() => {})
    },
    getLastComplexScore(){
      this.$http.post('/corporate/university/ai/train/result/lastComplexScore',{
      }).then((res)=>{
        this.compareScore = Math.abs(res.data.data - this.resultParams.complexScore)
        if(this.resultParams.complexScore > res.data.data) {
          this.compareStatus = '2'
        } else if(this.resultParams.complexScore < res.data.data) {
          this.compareStatus = '0'
        } else {
          this.compareStatus = '1'
        }
      })
    },
    genIDF(length){
      const eId = JSON.parse(sessionStorage.getItem("loginInfo")).eId
      return String(Date.now()) + String(eId);
    },
    base64ToBlob(base64Data) {
      let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new Blob([u8Arr], {type: fileType});
    },
    blobToFile(newBlob, fileName) {
      newBlob.lastModifiedDate = new Date();
      newBlob.name = fileName;
      return newBlob;
    },
    base64ToFile(base64Data, fileName) {
      let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new File([u8Arr], fileName, {type: fileType});
    },
    startFun() {
      navigator.mediaDevices.getUserMedia({
        video: true,//视频
        audio: true//音频
      }).then(function (stream) {
        this.MediaStreamTrackArr = stream.getTracks();
        videoObj.srcObject = stream
        videoObj.play();
      })
    },
    startRecordingNew(stream) {
      this.initializeRecorder(stream);
    },
    initializeRecorder(stream) {
      // MEDIA STREAM SOURCE -> ZERO GAIN >
      const realAudioInput = this.audioContext.createMediaStreamSource(stream);
      this.audioRecorder = new Recorder(realAudioInput, this.sendWebSocket);
    },
    sendWebSocket(audioDataView) {
      const view = audioDataView;
      if (window.wSocket && window.wSocket.readyState !== WS_STATE.OPEN) {
        // console.error(`WS connection error. WS readyState: ${window.wSocket.readyState}`);
      } else if (window.wSocket) {
         window.wSocket.send(view);
      }
    },
    closeWebSocket() {
      if (this.wSocket && this.wSocket.readyState === WS_STATE.OPEN) {
        const data = {
          message: {
            type: 'EOS',
          },
        };
        this.wSocket.send(JSON.stringify(data));
      }
    },
    getResultList(){
      this.$http.post('/corporate/university/ai/train/result/complex',{
        zjId: this.$route.query.zjId,
        aiId: this.$route.query.id,
        gradeConfId: this.$route.query.gradeConfId,
        gradeId: this.$route.query.gradeId,
        courseName: this.$route.query.courseName,
        courseId: this.$route.query.courseId,
        resultId: 0,
        resultType: 0,
        userUuid: JSON.parse(sessionStorage.getItem("zjloginInfo")).id,
        callId: this.$route.query.callId || this.genID,
        logId: this.logId,
      }).then((res)=>{
        if(this.$route.query.isList != 'isList'){
          this.loadingIns.close();
        }
        this.resultStatus = true
        const Data = res.data.data;
        this.complexScore = Data.complexScore
        this.standScoer = Data.analyse.standScoer
        let abilityModel = Data.abilityModel;
        this.analyseEmotion_1 = Data.analyse.negativeCount;
        this.analyseEmotion_2 = Data.analyse.neutralCount;
        this.analyseEmotion_3 = Data.analyse.positiveCount;
        this.analyseSpeed = Math.round(Data.analyse.speed);
        this.fastCount = Math.round(Data.analyse.fastCount);
        this.normalCount = Math.round(Data.analyse.normalCount);
        this.slowCount = Math.round(Data.analyse.slowCount);
        this.analyseFluency = Data.analyse.fluency;
        this.analyseViolation = Data.analyse.violation;
        this.analyseSensitive = Data.analyse.sensitive;
        this.gameInfoList = Data.gameInfoList
        this.coachChatLogList = Data.coachChatLogList
        this.videoParams.timeCounter = this.formart(Data.duration);
        this.logId = Data.query.logId
        this.videoParams.title = Data.query.courseName
        this.compareScore = Math.abs(Data.raiseScores)
        if(Data.raiseScores > 0) {
          this.compareStatus = '2'
        } else if(Data.raiseScores < 0) {
          this.compareStatus = '0'
        } else {
          this.compareStatus = '1'
        }
        if (abilityModel) {
          this.initCharts(this.$refs.echart_tar, abilityModel);
        }
      }).catch((e) => { this.loadingIns.close(); })
    },
    formart(value) {
      var s = parseInt(value);
      var m = 0;
      var h = 0;
      if(s >= 60) {
        m = parseInt(s / 60);
        s = parseInt(s % 60);
        if (m > 60) {
          h = parseInt(m / 60);
          m = parseInt(m % 60);
        }
      }
      var result = '' + (parseInt(s) < 10 ? '0' + parseInt(s) : parseInt(s));
          result = '' + (parseInt(m) < 10 ? '0' + parseInt(m) : parseInt(m)) + ':' + result;
          result = '' + (parseInt(h) < 10 ? '0' + parseInt(h) : parseInt(h)) + ':' + result;
      return result;
    },
    activePageMethod() {
      this.videoParams.userInfo = JSON.parse(
        sessionStorage.getItem("loginInfo")
      );
      classQuery = this.$route.query;
      this.videoParams.title = classQuery.name;
      this.videoParams.gradeName = classQuery.gradeName;
      let info = (this.xcSDKParams.info = JSON.parse(
        sessionStorage.getItem("zjloginInfo")
      ));

      this.xcSDKParams.userId = info.id;
      this.xcSDKParams.type = info.type;
      this.xcSDKParams.info = { info };
      sessionStorage.setItem("zjloginInfo", JSON.stringify(info));
      this.xcSDKParams.Server = this.initServer(
        this.host,
        `/coach/coach-api/ws/chat/${info.id}`
      );
      this.mountServerMethods();
    },
    closePage() {
      if (!this.hasbeenTested) {
        this.$confirm("退出后将不记录成绩。 确定退出吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          clearInterval(this.timer)
          this.timer = null
          this.stopRecordingNew();
          clearInterval(this.realInterval);
          this.xcSDKParams.Server?.closeConnect();
          this.$router.go(-1);
          this.endStudy()
        });
      } else {
        this.xcSDKParams.Server?.closeConnect();
        this.$router.go(-1);
        this.endStudy()
      }
    },
    endStudy() {
      EndStudy({
        "bussId": this.$route.query.id,
        "bussType": 9,//学习类型(1预习 2自测 3课件 4巩固 5考试)	
        "gradeConfId": this.$route.query.gradeConfId,
        "gradeId": this.$route.query.gradeId,//班级id	
        // "studyTime": 0
      }).then(res=>{
        if(res.data.code === 0){
          //this.details = res.data.data
        }
      })
    },
    startRecode() {
      const Server = this.xcSDKParams.Server;
      const _this = this.xcSDKParams;
      this.videoParams.recordState = !this.videoParams.recordState;
      if(this.trainType == '1') {
        if (this.videoParams.recordState) {
          this.asrTime = 1
          this.asrTimeF = setInterval(() => {
            this.asrTime++
          }, 1000);
          this.setImage()
          Server.startRecorderAudio();
        } else {
          clearInterval(this.asrTimeF)
          this.asrTimeF = null
          this.startRecodeStatus = true
          Server.stopRecorderAudio((data, send) => {
            // _this.srcUrl = URL.createObjectURL(data.blob);
            _this.recordData = data;
            this.zjChat('', "PROCESS", data.blob)
            // send();
          });
          this.changeMind = true
        }
      } else if(this.trainType == '0'){
        this.limeNum ++
        if (this.limeNum == 1) {
          this.initWSServer();
        }
        if (this.videoParams.recordState) {
          this.timer = setInterval(() => {
            this.setImage()
          }, 3000);
          if (!this.audioRecorder) return;
          this.resetRecordingInfo();
          this.audioContext.resume();
          this.audioRecorder.record();
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.startRecodeStatus = true
          this.endTime = new Date().getTime();
          this.stopRecordingNew();
          // this.wSocket.onclose();
          clearInterval(this.realInterval);
        }
      }
    },
    practiceAgain() {
      // console.log("practiceAgain")
      this.$router.go(0)
    },
    stopRecordingNew() {
      if (!this.audioRecorder) return;
      this.audioRecorder.stop();
      this.getWavAudio();
      this.clearTimeCount();
      if (this.wSocket && this.wSocket.readyState === WS_STATE.OPEN) {
        this.isLoading = true;
      }
    },
    startRecording() {
      this.start();
    },
    sendMessage(status) {
      // console.log('status', status);
    },
    stopRecording() {
      // this.wSocket.send();
      this.getBlob();
      this.clear();
      this.stop();
    },
    start() {
      this.audioInput.connect(this.recorder);
      this.recorder.connect(this.context.destination);
    },
    stop() {
      this.audioInput.disconnect(this.recorder);
      this.recorder.disconnect();
    },
    getBlob() {
      return this.audioData.encodeWAV();
    },
    clear() {
      this.audioData.clear();
    },
    scrollToBottom() {
      if (this.$refs.base) {
        this.$refs.base.scrollTop = this.$refs.base.scrollHeight;
      }
    },
    close() {
      this.dialogVisible = false;
    },
    countDown() {
      const that = this;
      const duration1 = moment.duration(0);
      const duration2 = moment.duration(5, "minutes");
      const diff = duration2 - duration1;
      let duration = moment.duration(diff, "milliseconds");
      this.countDownTime = "5分0秒";
      this.countDownInterval = setInterval(() => {
        if (that.closeExam !== "结束了") {
          duration = moment.duration(
            duration.asMilliseconds() - 1000,
            "milliseconds",
          );
          this.countDownTime = `${duration.minutes(
            "mm",
          )}分${duration.seconds()}秒`;
        }
      }, 1000);
    },
    // 结束5分钟计时
    endCountDown() {
      this.notOperatedTimes = 0;
      this.countDownTime = "5分0秒";
      clearInterval(this.notOperatedTimesSetInterObj);
      clearInterval(this.countDownInterval); // 关闭倒计时
    },
    // 开始5分钟计时
    startCountDown() {},
    initAudio() {
      const aid = document.getElementById('myAudio');
      this.$nextTick(() => {
        aid.load();
        aid.play();
      });
    },
    destroyed() {
      this.stopRecordingNew();
      clearInterval(this.emotionTimer);
      clearInterval(this.countDownInterval);
      clearInterval(this.notOperatedTimesSetInterObj);
      this.recClose();
      if (this.wavesurfer) {
        this.wavesurfer.destroy()();
      }
    },
    clearTimeCount() {
      this.count = 0;
      clearTimeout(this.recordTimer);
      this.recordTimer = undefined;
    },
    resetRecordingInfo() {
      this.recordText = '';
      this.gender = '';
      this.gscore = 0;
      this.emotion = '';
      this.escore = 0;
      this.fileList = [];
    },
    timeCount() {
      const MAX_RECORD_TIME = 30; // seconds
      this.count = this.count + 1;
      // this.recordSecond = this.count > 9 ? this.count : `0${this.count}`;
      if (this.count === MAX_RECORD_TIME) {
        this.stopRecording();
        return;
      }
      this.recordTimer = window.setTimeout(this.timeCount, 1000);
    },
    initWSServer() {
      let info = (this.xcSDKParams.info = JSON.parse(
        sessionStorage.getItem("zjloginInfo")
      ));
      // this.wSocket = new WebSocket(this.host + "/coach/coach-api/ws/chat/" + info.id);
      this.wSocket = new WebSocket(this.host + '/coach-multiple-api/ws/v1/chat/' + this.systemId + '/dbs_' + this.genID);
      this.wSocket.binaryType = 'arraybuffer';
      window.wSocket = this.wSocket;
      this.wSocket.onopen = this.recognitionWSOnOpen;
      this.wSocket.onerror = this.recognitionWSOnError; // 当发生错误时会触发error事件
      this.wSocket.onmessage = this.recognitionWSOnMessage; // 当连接关闭时会触发message事件
      this.wSocket.onclose = this.recognitionWSOnClose;
    },
    recognitionWSOnOpen() {
      if (this.wSocket.readyState !== 1) {
        this.sleep(1000);
      } else {
        const data = {
          message: {
            type: 'INIT',
          },
        };
        this.wSocket.send(JSON.stringify(data));
        this.wSocket.send(this.buildWaveHeader());
      }
    },
    getWavAudio() {
      const blob = this.audioRecorder.getWavAudio();
      if (blob === undefined) {
        setTimeout(this.getWavAudio, 1000);
      } else {
        this.recoredBlob = blob;
        this.fileList = [blob];
      }
    },
    recognitionWSOnError() {
      this.$alert(this.$t('asr.notify.recognize_error'), this.$t('general.hint'));
    },
    recognitionWSOnMessage(event) {
      if (event.data === "pong") return;
      if (typeof event.data === "string") {
        const res = JSON.parse(event.data);
        if (res) {
          this.chatDeal1(res);
        } else {
          this.$message.error(res.message);
        }
        this.waitasr = true;
        this.startCountDown(); // 转译结束后，结束5分钟计时
      } else {
        // console.log('event', event);
      }
    },
    recognitionWSOnClose() {
      if (this.wSocket && this.wSocket.readyState === WS_STATE.OPEN) {
        const data = {
          message: {
            type: 'EOS',
          },
        };
        this.wSocket.send(JSON.stringify(data));
      }
    },
    async chatDeal1(res) {
      if (res.result && Number(res.result.speed) > 0) {
        this.xcSDKParams.speed = Math.round(res.result.speed)
      }
      if (res.type == 'TRANSCRIPT') {
        const data = res.result;
        const paraIdxB = this.chatData.find(item => item.paraIdx === data.paraIdx)
        if (paraIdxB) {
          let newText = ''
          const chat = {
            content: data.content,
            idx: data.idx
          }
          if (this.conbinArr.find(i => i.idx === data.idx)) {
            this.conbinArr[data.idx].content = data.content
          } else {
            this.conbinArr.push(chat)
          }
          this.conbinArr.forEach((item) => {
            newText += item.content
          })
          this.parah = newText;
          this.chatData[paraIdxB.paraIdx + this.backNum] = {
            content: this.parah,
            paraIdx: paraIdxB.paraIdx
          }
          // console.log(paraIdxB.paraIdx + this.backNum)
          // console.log(this.chatData)
          this.$forceUpdate();
        } else {
          this.parah = ''
          this.conbinArr = []
          this.chatData.push({
            content: data.content,
            paraIdx: data.paraIdx
          })
          this.$forceUpdate();
        }
      } else if(res.type == 'PARAGRAPH') {
        // console.log(this.chatData)
        this.counter++
        // this.chatData.push({
        //   content:res.result.content,
        // })
        this.zjChat(res.result, "PROCESS", '' , this.counter)
      } else {
        if(res.status == 400) {
          this.endTestFun()
          return
        }
        if(res.result) {
          this.backNum += 1
          // this.chatData.push({
          //   content: res.result
          // })
        }
        this.$forceUpdate();
      }


      // if(res.status == 400) {
      //   this.endTestFun()
      //   return
      // }
      // if (res.result && (res.result.finalRes == true)) {
      //   this.counter++
      //   console.log(this.counter)
      //   this.chatData.push({
      //     content:res.result.content,
      //     paraIdx:res.result.paraIdx
      //     })
      //   this.zjChat(res.result.content, "PROCESS", '' , this.counter)
      // }
    },
    startCountDown() {},
    buildWaveHeader() {
      const numChannels = 2;
      const sampleRate = 44100 / 2;
      const bytesPerSample = 2;
      const blockAlign = numChannels * bytesPerSample;
      const byteRate = sampleRate * blockAlign;
      const dataSize = 0;
      const buffer = new ArrayBuffer(44);
      const dv = new DataView(buffer);
      let p = 0;
      const writeString = (s) => {
        for (let i = 0; i < s.length; i += 1) {
          dv.setUint8(p + i, s.charCodeAt(i));
        }
        p += s.length;
      };
      const writeUint32 = (d) => {
        dv.setUint32(p, d, true);
        p += 4;
      };
      const writeUint16 = (d) => {
        dv.setUint16(p, d, true);
        p += 2;
      };
      writeString('RIFF'); // ChunkID
      writeUint32(dataSize + 36); // ChunkSize
      writeString('WAVE'); // Format
      writeString('fmt '); // Subchunk1ID
      writeUint32(16); // Subchunk1Size
      writeUint16(1); // AudioFormat
      writeUint16(numChannels); // NumChannels
      writeUint32(sampleRate); // SampleRate
      writeUint32(byteRate); // ByteRate
      writeUint16(blockAlign); // BlockAlign
      writeUint16(bytesPerSample * 8); // BitsPerSample
      writeString('data'); // Subchunk2ID
      writeUint32(dataSize); // Subchunk2Size
      return buffer;
    },
    requestUserMedia(onGetUserMediaCallback) {
      window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
      this.audioContext = new AudioContext();
      if (this.media) {
        let option = { audio: true, video: true };
        if (!this.videoFliag) {
          // false 只有audio
          option = { audio: true };
        }
        navigator.mediaDevices.getUserMedia(option).then((stream) => {
          if (this.videoFliag) {
            const video = document.querySelector("video");
            video.srcObject = stream;
            video.play();
          }
          this.mediaStreamTrack = stream;
          onGetUserMediaCallback(stream);
        }).catch((err) => {
          this.handlerErrorUserMedia();
        });
      }
    },
    // ws结束
    startTest() {
      this.labels()
      this.reset_xcSDKParams();
      this.computedTimeCounter();
      this.mainParams.startTest = true;
      this.mainParams.hasResultScore = false;
      // this.xcSDKParams.Server.ServerConnect(() => {
      //   console.log("链接成功");
      // });
      this.genID = this.genIDF(13)
      this.xcSDKParams.Server.setCallId(this.genID);
      this.toggleFlag = true;
      // if (this.trainType == '0') {
      //   this.initWSServer();
      // }
    },
    endTest() {
      this.$confirm("确定结束本次练习吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.endTestFun()
      });
    },
    endTestFun() {
      this.loadingIns = this.$loading({
        fullscreen: true,
        text: "正在获取结果...",
        background: "rgba(255,255,255, .6)",
      });
      this.mainParams.startTest = false;
      this.mainParams.hasResultScore = true;
      if (this.trainType == '0') {
        this.stopRecordingNew();
        this.wSocket.onclose();
        clearInterval(this.realInterval);
      }
      this.zjChat('', 'END')
      
      // this.xcSDKParams.Server.getResult();
      // var that = this
      // setTimeout(function () {
      //   that.delay();
      // },5000);
      this.hasbeenTested = true;
      this.stopTimeCounter();
    },
    delay() {
      this.xcSDKParams.Server.getResult();
      // console.log('111111')
    },
    computedTimeCounter() {
      const getTime = () => new Date().getTime();
      let startTmp = getTime();
      this.videoParams.timerIns = setInterval((_) => {
        this.videoParams.timeCounter = TimestampToDate(
          getTime() - startTmp,
          "00:mm:ss"
        );
      }, 1000);
    },
    stopTimeCounter() {
      clearInterval(this.videoParams.timerIns);
    },
    mountServerMethods() {
      const Server = this.xcSDKParams.Server;
      const _this = this.xcSDKParams;
      const info = _this.info;
      // SDK 返回值
      Server.respones((data) => {
        if (data.type == "getEmotion") {
          _this.emotion = data.res.emotionText;
        }
        if (data.type == "getResult") {
          const Data = data.res.data;
          let abilityModel = Data.abilityModel;
          this.resultParams.complexScore = Data.complexScore;
          // this.resultParams.gameInfoList = Data.gameInfoList;
          this.resultParams.analyse = Data.analyse;
          this.resultParams.infractionsNum = Data.infractionsNum;
          this.$forceUpdate();
          if(Data.responseBean) {
            this.resultParams.emotionList_first = Data.responseBean.emotionPercentList[0];
          }
          if(Data.normsSpeechRes) {
            this.resultParams.sensitiveWordList = Data.normsSpeechRes.sensitiveWordList;
          }
          if (abilityModel) {
            this.initCharts(this.$refs.echart_tar, abilityModel);
          }
          this.loadingIns.close();
        }
      });

      Server.register("recordFile", (P) => {
        P.then((url) => {
          _this.srcUrl = url;
        });
      });

      // 注册语音翻译处理
      Server.register("roboTranslateTxt", (text) => {
        _this.msgList.push({
          isRobot: false,
          text,
        });
        Server.send(
          JSON.stringify({
            startTime: _this.recordData.recordStartTime,
            endTime: _this.recordData.recordEndTime,
            text,
          })
        );
        _this.recordData = {};
        this.bottomState();
      });

      // 机器人返回的陪练信息
      Server.register("roboTestText", (text) => {
        this.xcSDKParams.msgList.push({
          isRobot: true,
          text,
        });
        this.grassSpeak.push('0')
        this.bottomState();
      });

      Server.use((msg, patch, utils) => {
        if (typeof msg.data == "string") {
          const data = JSON.parse(msg.data);
          // 1 机器人根据发送的文字返回的陪练语句 0机器人解析语音之后返回的文字
          if (data.direction == 0) patch("roboTranslateTxt", data.text);
          if (data.direction == 1) patch("roboTestText", data.text);
          if (data.callId) _this.callId = data.callId;
          if (data.resultId) _this.resultId = data.resultId;
          if (data.speed) _this.speed = data.speed;
          if (data.sensitiveWords) _this.sensitiveWords = data.sensitiveWords;
          if (data.sensitiveWords)
            this.sensitiveWordList = data.sensitiveWords;
          if(data.grass) {
            this.grassSpeak.push(data.grass)
          } else {
            this.grassSpeak.push('0')
          }
        } else {
          patch("recordFile", utils.readEncodeToObjectSrc(msg.data));
        }
      });
      // 错误中转站
      Server.catch((err, msg) => {
        // this.loadingIns.close();
      });
      info.courseName = classQuery.courseName;
      info.classQuery = classQuery;
      Server.setUser(info);
      // 设置考试类型 0 练习 | 1 考试
      // 默认 0 练习
      Server.setExaminationType(0);
    },
    textKeyborad(e) {
      if (e.code == "Enter") {
        this.startRecodeStatus = false;
        let val = this.xcSDKParams.inputVal;
        this.zjChat(val, "PROCESS")
        this.xcSDKParams.msgList.push({
          isRobot: false,
          text: val,
        });
        // this.xcSDKParams.Server.send(JSON.stringify({ text: val }));
        this.xcSDKParams.inputVal = "";
        this.bottomState();
      }
    },
    beforeDestroy() {
      this.$_bus.$off('userChat');
      clearInterval(this.realInterval);
    },
    bottomState() {
      if (window.scrollTimer) clearTimeout(window.scrollTimer);
      window.scrollTimer = setTimeout((_) => {
        clearTimeout(window.scrollTimer);
        let scrollH = this.$refs.scrollTar.scrollHeight;
        this.$refs.scrollTar.scrollTop = scrollH;
      }, 300);
    },
    //  绘制图片（拍照功能）
    setImage() {
      
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      var _this = this;
      // 点击，canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接

      // canvas转base64
      const base64Data = this.thisCancas.toDataURL('image/png', 0.95);
      _this.imgSrc = base64Data;
      // 方式1
      // base64转blob
      const blob = this.base64ToBlob(base64Data);
      // blob转file
      const file1 = this.blobToFile(blob, '文件名');

      // 方式2
      // base64转file
      this.file2 = this.base64ToFile(base64Data, '文件名');
      // console.log(this.file2)
      if (this.trainType == '0') {
        this.getImageEmotion(this.base64ToFile(base64Data, '文件名'))
      }
    },
    // base64转文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/basics.scss";
.xc__close {
  position: absolute;
  right: 40px;
  display: flex;
  height: 34px;
  width: 34px;
  background: rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 26px;
  cursor: pointer;
}
.cor__primary {
  color: #2d70f8;
}
.practice__video_item {
  font-size: 16px;
  color: #999;
  padding-top: 10px;
}
.practice__video_rules {
  margin-top: 20px;
  padding: 20px 24px;
}
.disabled {
  background: #eee;
}
.list__item {
  display: flex;
  align-items: center;
}
.list__item::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 2px solid;
  border-color: $font_color_blue;
  border-radius: 50%;
  margin-right: 10px;
}
.list__item_d::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 2px solid;
  border-radius: 50%;
  margin-right: 10px;
  border-color: $font_color_red;
}
.practice__result_stream {
  height: 320px;
  width: 320px;
  margin-bottom: 20px;
  background: #eee;
}
.mirco__icon {
  font-size: 30px;
}
.s_button {
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 80px;
  height: 30px;
  background: #2d70f8;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  top: 5px;
  right: 20px;
  // left: 100px;
  // bottom: 100px;
  z-index: 99;
}
.control_video_c{
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  top: 5px;
  right: 110px;
  // left: 50px;
  // bottom: 100px;
  z-index: 99;
  img{
    width: 30px;
    height: 30px;
  }
}
.del_styu {
  color: #333;
  background: #F8F8F8;
}
.m__button {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 40px;
  display: flex;
  width: 140px;
  height: 50px;
  background: linear-gradient(133deg, #ffb734 0%, #ff8512 100%);
  border-radius: 44px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
}
.change_mind{
  background: #B6B8BD;
  cursor: default;
}
.realtime_voice{
  right: 90px;
}
.practice__result {
  height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: auto;
}
.message__input_tar {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  border-top: 1px solid #eee;
  padding: 20px;
  box-sizing: border-box;
}
.message__input {
  position: relative;
  width: 100%;
  height: 150px;
}
.practice__main_view {
  height: calc(100vh - 280px);
  overflow: hidden;
  overflow-y: auto;
}
.message__detail {
  display: flex;
  background: #f4f5f6;
  min-height: 36px;
  align-items: center;
  padding: 4px 12px;
  font-size: 16px;
  color: #1F2329;
  border-radius: 8px;
  margin-left: 16px;
}
.message__line {
  width: 100%;
  display: flex;
  align-items: top;
  margin-bottom: 10px;
  padding-right: 76px;
  box-sizing: border-box;
}
.message__avator {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.message__self {
  justify-content: flex-end;
  padding-right: 0;
  padding-left: 76px;
  .message__detail {
    margin-left: 0;
    margin-right: 16px;
    background: #2D70F8;
    color: #fff;
    max-width: 400px;
  }
}
.message__self_box{
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end !important;
  flex-wrap: wrap;
  padding-left: 40px;
}
.practice__main {
  height: calc(100vh - 40px);
  position: relative;
  padding: 40px;

  box-sizing: border-box;
}
.col__font--default {
  color: #333;
}
.practice__result_list {
  padding-left: 20px;
  width: 100%;
  text-align: left;
}
.practice__result_listitem {
  padding-bottom: 10px;
  color: #999999;
  font-size: 16px;
}
.practice__header {
  height: 40px;
  width: 100%;
  background: $bg_blue_2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  // font-size: 18px;
}
.practice__content {
  display: flex;
  height: calc(100vh - 40px);
  padding: 30px 40px;
  background: $bg_grey;
  box-sizing: border-box;
  overflow: hidden;
}
.practice__info {
  flex: 0 0 320px;
  padding-right: 20px;
  box-sizing: border-box;
}
.practice__main {
  flex: 1;
  // min-width: 852px;
}
.practice__result {
  flex: 0 0 320px;
  margin-left: 20px;
}
.practice__video_view {
  position: relative;
  height: 310px;
  padding: 40px;
  box-sizing: border-box;
  text-align: center;
}
.bg__white {
  background: #fff;
}
.practice__video_avator {
  padding-bottom: 5px;
}
.avator {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.practice__video_title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}
.practice__video_sub {
  font-size: 16px;
  color: #999999;
  margin: 4px;
  margin-top: 20px;
}
.practice__video_time {
  position: absolute;
  bottom: 40px;
  left: 88px;
  font-size: 20px;
}
.practice__time_cor {
  color: $font_color_red;
}
.practice__result_item {
  padding: 25px 0;
  position: relative;
  .lastScore{
    position: absolute;
    top: 24px;
    right: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
}
.practice__result_title {
  font-size: 16px;
  font-weight: 600;
}
.practice__result_title::before {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  background: $font_color_blue;
  margin-right: 10px;
}
.grass_box{
  margin: 0 20px;
  .grass_list{
    margin-top: 20px;
    .part1{
      display: flex;
      justify-content: space-between;
      .name{
        font-size: 16px;
        font-weight: 400;
        color: #303132;
        line-height: 22px;
      }
      .status{
        font-size: 16px;
        font-weight: 400;
        color: #919398;
        line-height: 22px;
      }
    }
    .part2{
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
      .part2_b{
        width: 63px;
        height: 10px;
        background: #F5F6F8;
        display: inline-block;
      }
      .part2_bb{
        position: absolute;
        top: 3px;
        left: 0;
      }
      .part2_b_box{
        position: relative;
      }
      .part2_b_box:first-child .part2_b{
        border-radius: 10px 0px 0px 10px;
      }
      .part2_b_box:last-child .part2_b{
        border-radius: 0px 10px 10px 0px;
      }
    }
    .part3{
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      font-weight: 400;
      color: #919398;
      line-height: 22px;
    }
  }
}
.practice__result_detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background: #ffb042;
  border: 5px solid #f8dfc5;
  color: #fff;
  .size--inner {
    font-size: 60px;
  }
}
.practice__result_content {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.echart_tar {
  display: inline-block;
  width: 300px;
  height: 300px;
}
.infractions_num{
  font-size: 16px;
  font-weight: 400;
  color: #F04B4A;
  line-height: 28px;
}
.con_sensitive{
  font-size: 16px;
  font-weight: 400;
  color: #F04B4A;
  line-height: 28px;
}
.see_sensitive{
  font-size: 16px;
  font-weight: 400;
  color: #2D70F8;
  line-height: 28px;
  margin-left: 16px;
  cursor: pointer;
}
.infractions_see{
  font-size: 20px;
  font-weight: 400;
  color: #2D70F8;
  line-height: 28px;
  margin-left: 16px;
}
.grass_speak_box{
  text-align: right;
  display: flex;
  flex-direction: column;
  .grass_speak{
    // background: #66B92E;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: right;
    margin-top: 8px;
    width: 186px;
    // margin-right: -30px;
    .grasses{
      border-radius: 100px 0px 0px 100px;
      height: 28px;
      line-height: 28px;
      padding: 0 14px;
      display: inline-block;

    }
  }
  .grass_speak:first-child{
    margin-top: 0;
  }
}
.warn_msg_box{
  margin-right: 70px;
  margin-top: 10px;
  .warn_msg{
    text-align: right;
    line-height: 33px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F04B4A;
    .icon_warn {
      width: 32px;
      vertical-align: middle;
    }
  }
  .reference{
    max-width:807px;
    background: #F4F5F6;
    border-radius: 8px;
    // margin-top:10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1F2329;
    // line-height: 40px;
    padding: 7px;
    .refernce_txt{
      background: #FFA528;
      border-radius: 100px;
      padding: 0 9px;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
.intents_box{
  margin-top: 10px;
  margin-right: 76px;
  .intents_tiem{
    display: inline-block;
    .intents_list{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 33px;
      background: #F4F5F6;
      border-radius: 4px;
      padding: 6px 12px;
      margin-left: 10px;
    }
    .intents_list.hit{
      color: #18CC93;
      background: #E3F7F1;
    }
  }
}
.speak_box{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.message__line{
  display: flex;
  align-items: center;
}
.deduct_points_box{
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  .line{
    position: absolute;
    border-bottom: 1px dashed #F04B4A;
    width: 100%;
    top: 20px;
  }
  .deduct_points{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #F04B4A;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    position: relative;
  }
}
</style>