const recordWavWorker = new Worker('./wav/recorderWorker.js');
const encoderMp3Worker = new Worker('./mp3/mp3Worker.js');

const Recorder = function (source, audioProcessCallback) {
  const thisRecorder = this;
  const bufferLen = 256;
  let recording = false;
  this.context = source.context;
  this.wavBlob = undefined;
  this.mp3Blob = undefined;
  this.node = (this.context.createScriptProcessor || this.context.createJavaScriptNode).call(this.context, bufferLen, 1, 1);
  this.node.connect(this.context.destination);
  function floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  }
  this.node.onaudioprocess = function (e) {
    if (!recording) return;
    const channelLeft = e.inputBuffer.getChannelData(0);
    const buffer = new ArrayBuffer(channelLeft.length * 2);
    const view = new DataView(buffer);
    floatTo16BitPCM(view, 0, channelLeft);
    if (typeof audioProcessCallback === 'function') {
      audioProcessCallback(view);
    }
    encoderMp3Worker.postMessage({
      command: 'encode',
      buf: channelLeft,
    });
    recordWavWorker.postMessage({
      command: 'record',
      buffer: channelLeft,
    });
  };
  source.connect(this.node);
  this.record = function () {
    if (recording) return false;
    recording = true;
    const sampleRate = this.context.sampleRate || 44100;
    console.log("Initializing WAV");
    recordWavWorker.postMessage({
      command: 'init',
      config: {
        sampleRate,
        bitRate: 16,
      },
    });
    encoderMp3Worker.postMessage({
      command: 'init',
      config: {
        channels: 1,
        mode: 3,
        samplerate: 22050,
        bitrate: 64,
        insamplerate: sampleRate,
      },
    });
  };
  this.stop = function () {
    if (!recording) return;
    recordWavWorker.postMessage({
      command: 'finish',
    });
    encoderMp3Worker.postMessage({
      command: 'finish',
    });
    recording = false;
  };
  this.getWavAudio = () => {
    if (this.wavBlob !== undefined) {
      return this.wavBlob;
    }
    return undefined;
  };
  function endFile(blob, extension) {
    console.log("Done converting to " + extension);
    console.log("the blob " + blob + " " + blob.size + " " + blob.type);
    const url = URL.createObjectURL(blob);
    const li = document.createElement('li');
    const hf = document.createElement('a');
    hf.href = url;
    hf.download = new Date().toISOString() + '.' + extension;
    hf.innerHTML = hf.download;
    li.appendChild(hf);

    const au = document.createElement('audio');
    au.controls = true;
    au.src = url;
    li.appendChild(au);
  }
  encoderMp3Worker.onmessage = function (e) {
    const command = e.data.command;
    console.log('encoderMp3Worker - onmessage: ' + command);
    switch (command) {
      case 'data':
        console.log('Receiving data from mp3-Encoder');
        break;
      case 'mp3':
        const buf = e.data.buf;
        endFile(buf, 'mp3');
        thisRecorder.mp3Blob = e.data.buf;
        break;
      default:
        break;
    }
  };
  recordWavWorker.onmessage = function (e) {
    const command = e.data.command;
    console.log('recordWavWorker - onmessage: ' + command);
    switch (command) {
      case 'wav':
        endFile(e.data.buf, 'wav');
        thisRecorder.wavBlob = e.data.buf;
        break;
      default:
        break;
    }
  };
};
window.Recorder = Recorder;
export default Recorder;
