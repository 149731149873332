import { courseDays, 
    dayDetail,
    studentExam, 
    courseDaysTea, 
    dayDetailTea,
    getClassDetail, 
    getPicList, 
    getAnalyzer, 
    getexamRanke, 
    getfindPapersByGraderId,
    getRank, getAiAnalyzer,getstudyRanke,getCourseName,getCourseDetail, 
    getCoursestuDetail, gradePaper,startStudy,endStudy,waitingTest,listMyWrongQu,
    checkStatusClass,aiDetail,
    gradeUpdate} from './api/details'
import http from '../utils/request';
// 学生课程详情  获取班级上课天数
export const  COURSEDAYS = (opt) => {
    return http.get(courseDays,{params: opt})
}
export const  DAYDETAIL = (opt) => {
    return http.get(dayDetail,{params: opt})
}
// 获取用户信息和考试详情
export const  STUDENTEXAM = (data) => {
    return http.post(studentExam,data)
}

// 老师课程详情  获取班级上课天数
export const  COURSEDAYSTEA = (opt) => {
    return http.get(courseDaysTea,{params: opt})
}
export const  DAYDETAILTEA = (opt) => {
    return http.post(dayDetailTea,opt)
}

// 班级详情
export const  GETCLASSDETAIL = (opt) => {
    return http.get(getClassDetail,{params: opt})
}
// 班级详情图文list
export const  GETPICLIST = (opt) => {
    return http.post(getPicList,opt)
}

//数据报告
export const GETANALYZER = () => { //课程分析
    return http.post(getAnalyzer)
}

export const GETRANK = () => { //课程排名
    return http.post(getRank)
}

export const GETAIANALYZER = () => { //课程排名
    return http.post(getAiAnalyzer)
}
//考试排行
export const GETEXAMRANKE = (opt) => {
    return http.post(getexamRanke,opt)
}

//学习排行
export const GETSTUDYRANKE = (opt) => {
    return http.post(getstudyRanke,opt)
}

//讲师端课程名字
export const GETCOURSENAME = (opt) => {
    return http.post(getCourseName,opt)
}

//讲师端课程详情
export const GETCOURSEDETAIL = (opt) => {
    return http.post(getCourseDetail,opt)
}

//学员端课程详情
export const GETCOURSESTUDETAIL = (opt) => {
    return http.post(getCoursestuDetail,opt)
}

//发布考试
export const GRADEPAPER = (opt) => {
    return http.post(gradePaper,opt)
}

// 开始学习
export const StartStudy = (opt) => {
    return http.post(startStudy,opt)
}
//结束学习
export const EndStudy = (opt) => {
    return http.post(endStudy,opt)
}
//待考试列表
export const WaitingTest = (opt) => {
    return http.post(waitingTest,opt)
}
//错题列表
export const ListMyWrongQu = (opt) => {
    return http.post(listMyWrongQu,opt)
}
//陪练记录
export const AiDetail = (opt) => {
    return http.post(aiDetail,opt)
}

//查询是否开班
export const CheckStatusClass = (opt) => {
    return http.get(checkStatusClass,{params: opt})
}

//编辑班级
export const GradeUpdate = (opt) => {
    return http.post(gradeUpdate,opt)
}

//编辑班级
export const getfindPapersByGrader = (opt) => {
    return http.post(getfindPapersByGraderId,opt)
}