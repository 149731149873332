<template>
  <div class="practice__header">
    陪练列表
    <span class="xc__close" @click="closePage">
      <i class="el-icon-close"></i>
    </span>
  </div>
  <div class="table_con">
    <el-table :data="details" style="width: 100%">
      <el-table-column
        prop=""
        label="序号"
        width="120"
        type="index"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="陪练时间"
        width="250"
      ></el-table-column>
      <el-table-column label="用时" width="160">
        <template v-slot="scope">
          <span>{{formart(scope.row.durationTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="成绩">
        <template v-slot="scope">
          <span>{{scope.row.complexScore}}分</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="">
        <template v-slot="scope">
          <span class="see_result" @click="seeResult(scope.row)">查看</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { reactive } from "vue";
import { AiDetail } from "@/server/details";
export default {
  setup() {
    const classInfo = reactive({
      arrangerTeacher: 0,
      endGradeTime: "2020.10.28 08:00",
      gradeConfId: 0,
      gradeConfName: "新生班",
      gradeName: "2020年第8期客服",
      id: 0,
      inClass: 0,
      masterId: 0,
      masterName: "田世宣",
      openGradeTime: "2020.10.26 08:00",
      peopleNum: 0,
      stuLists: [],
    });
    return {
      classInfo,
    };
  },
  data: () => ({
    details: [],
  }),
  methods: {
    formart(value) {
      var s = parseInt(value);
      var m = 0;
      var h = 0;
      if(s >= 60) {
        m = parseInt(s / 60);
        s = parseInt(s % 60);
        if (m > 60) {
          h = parseInt(m / 60);
          m = parseInt(m % 60);
        }
      }
      var result = '' + parseInt(s) + '秒';
      if (m > 0) {
        result = '' + parseInt(m) + '分' + result;
      }
      if (h > 0) {
        result = '' + parseInt(h) + '时' + result
      }
      return result;
    },
    closePage() {
      this.$router.go(-1);
    },
    seeResult(parme) {
      parme.isList = 'isList'
      this.$router.push({ path: "/practicePartner", query:parme });
    },
  },
  created() {
    AiDetail({
      aiId: this.$route.query.id,
      gradeId: this.$route.query.gradeId,
      trainCount: this.$route.query.trainCount,
      userId: JSON.parse(sessionStorage.getItem("loginInfo")).eId,
    }).then((res) => {
      if (res.data.code === 0) {
        this.details = res.data.data;
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色
    document
      .querySelector("#app")
      .setAttribute("style", "background-color:#fff");
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector("#app").setAttribute("style", "");
    next();
  },
};
</script>

<style lang="scss" scoped>
.see_result {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #2d70f8;
}
.practice__header {
  height: 80px;
  width: 100%;
  background: $bg_blue_2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
}
.xc__close {
  position: absolute;
  right: 40px;
  display: flex;
  height: 34px;
  width: 34px;
  background: rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 26px;
  cursor: pointer;
}
.table_con {
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
  border: 1px solid #dddddd;
}
</style>
<style lang="scss">
.table_con {
  .el-table__header-wrapper {
    th {
      background: #f8f8f8;
    }
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #dddddd;
    padding: 0 22px;
  }
  .el-table .cell {
    font-size: 16px;
    color: #333;
    div {
      font-size: 16px;
    }
  }
  .el-table__row {
    height: 80px;
  }
  .has-gutter {
    tr {
      height: 60px;
      .cell {
        color: #999;
      }
    }
  }
  .el-table__row{
    td:last-child{
      text-align: right;
    }
  }
}
</style>