<template>
   <div v-if="clickStatus" class="violation_box">
      <div class="violation">
          <div class="title">
            <div class="txt">违规详情 {{indexVal + 1}}/{{violationData.length}}</div>
            <div class="close" @click="hideViolation">
                <span class="xc__close">
                  <i class="el-icon-close"></i>
                </span>
            </div>
          </div>
          <!-- <div class="violation_content" v-for="(item, index) in violationData" :key="index"> -->
          <div class="violation_content">
              <el-carousel arrow="always" :autoplay="false" @change="getIndex" height="450px">
                <el-carousel-item v-for="(item, index) in violationData" :key="index">
                  <div class="other msg_box">
                    <img class="avatar" :src="require('../../assets/images/header.png')"/>
                    <div class="message">{{item.question}}</div>
                  </div>
                  <div class="me msg_box">
                    <div class="message">{{item.answer}}</div>
                    <img class="avatar" :src="require('../../assets/images/header.png')"/>
                  </div>
                  <div class="warn_msg">
                    <img :src="require('../../assets/images/icon_warn.png')" class="icon_warn">  {{item.sensitiveWord}}
                  </div>
                  <div class="reference">
                    <span class="refernce_txt">参考</span>{{item.standAnswer}}
                  </div>
                </el-carousel-item>
              </el-carousel>
          </div>
          <div class="opt_btn">
            <div class="btn_cancel" @click="hideViolation">取消</div>
            <div class="btn_next" @click="hideViolation">确定</div>
          </div>
      </div>
   </div>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  props: ["violationData", "clickStatus"],
  setup(props, context) {
    const indexVal = ref(0)
    const getIndex = (val) => {
      indexVal.value = val
      console.log(indexVal.value)
    }
    const hideViolation = () => {
      context.emit('itemclick')
      getIndex(0)
    }
    return {
      hideViolation,
      getIndex,
      indexVal
    }
  }
}
</script>
<style scoped lang="scss">
  .violation_box {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:100;
    left:0;
    top:0;
    .violation{
      width: 800px;
      height: 580px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #DDDDDD;
      position:absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      .title{
        height: 50px;
        padding: 0 24px 0 40px;
        position:relative;
        .txt{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333;
          position:absolute;
          top:14px;
        }
        .close {
          width:34px;
          height: 34px;
          position:absolute;
          top:10px;
          right:24px;
          font-size: 30px;
          text-align: center;
          cursor: pointer;
        }
      }
      .violation_content{
        width: 700px;
        height: 440px;
        border: 1px solid #DDDDDD;
        margin: 0 auto;
        padding: 20px 20px 0;
        .msg_box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 30px;
          .avatar {
            height: 60px;
            width: 60px;
          }
          .message{
            max-width: 807px;
            line-height: 40px;
            padding: 4px 12px;
          }
        }
        .other {
          padding-right: 177px;
          .avatar {
            margin-right: 16px;
          }
          .message {
            background: rgba(244, 245, 246, 1);
            border-radius: 0 16px 16px 16px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1F2329;
          }
        }
        .me {
          justify-content: flex-end;
          padding-left: 177px;
          .avatar {
            margin-left: 16px;
          }
          .message {
            background: rgba(45, 112, 248, 1);
            border-radius: 16px 0 16px 16px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
        .warn_msg{
          padding-right:60px ;
          text-align: right;
          line-height: 33px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F04B4A;
          .icon_warn {
            width: 32px;
            vertical-align: middle;
          }
        }
        .reference{
          max-width:807px;
          margin-right: 80px;
          background: #F4F5F6;
          border-radius: 8px 0px 8px 8px;
          position: absolute;
          right:0;
          margin-top:20px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1F2329;
          line-height: 20px;
          padding: 7px;
          .refernce_txt{
            background: #FFA528;
            border-radius: 100px;
            padding: 0 9px;
            font-size: 14px;
            color: #FFFFFF;
            margin-right: 10px;
          }
        }
      }
      .opt_btn{
        padding: 13px 30px;
        display: flex;
        justify-content: flex-end;
        div{
          width:80px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
        }
        .btn_next {
          background: #2e7fff;
          color: #fff;
          margin-left: 30px;
        }
        .btn_cancel {
          border: 1px solid #ddd;
          color: #666;
        }
      }
    }
  }
  :deep(.el-carousel__arrow--right){
    background: #2e7fff;
  }
  :deep(.el-carousel__arrow--left){
    background: #2e7fff;
  }
</style>
