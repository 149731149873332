export const courseDays = '/corporate/university/student/get/course/days'
export const dayDetail = '/corporate/university/student/get/day/detail'
export const studentExam = '/corporate/university/student/start/exam'

export const courseDaysTea = '/corporate/university/grade/get/course/days'
export const dayDetailTea = '/corporate/university/grade/get/day/course/detail'

export const getClassDetail = '/corporate/university/grade/get/detail'
export const getPicList = '/corporate/university/student/get/res/detail'

export const getAnalyzer = '/corporate/university/study/course/analyzer'
export const getRank = '/corporate/university/study/course/rank'
export const getAiAnalyzer = '/corporate/university/study/ai/analyzer'

export const getexamRanke = '/corporate/university/ranke/examRanke'
export const getstudyRanke = '/corporate/university/ranke/studyRanke'

export const getCourseName = '/corporate/university/course/courseName/summary'
export const getCourseDetail = '/corporate/university/course/courseDetail/summary'
export const getCoursestuDetail = '/corporate/university/course/stu/courseDetail/summary'

export const gradePaper = '/corporate/university/grade/publish/paper'


//开始学习
export const startStudy = '/corporate/university/student/startStudy'

//结束学习
export const endStudy = '/corporate/university/student/endStudy'

//待考试列表
export const waitingTest = '/corporate/university/student/waitingTest'

//错题列表
export const listMyWrongQu = '/corporate/university/exam/listMyWrongQu'

//陪练记录
export const aiDetail = '/corporate/university/grade/ai/aiDetail'

//查询是否开班
export const checkStatusClass = '/corporate/university/grade/selectGradeStartClass'

//编辑班级信息
export const gradeUpdate = '/corporate/university/grade/update'

//编辑班级信息
export const getfindPapersByGraderId = '/corporate/university/exam/findPapersByGraderId'
