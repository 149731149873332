<template>
<div>
  <Menu :menuTitle="title"/>
  <div class="v-table">
    
    <!-- 考试成绩 -->
    <el-table
        :data="list"
        :empty-text='"暂无数据"'
        style="width: 100%">

        <el-table-column label="序号" width="120" >
            <template #default="scope">
              <div >{{scope.$index + 1}}</div>
            </template>
        </el-table-column>
        
        <el-table-column prop="durationTime" label="陪练时间" width="260" >
          <template #default="scope">
            <span style="">{{ scope.row.createDate }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="用时" width="160" >
          <template #default="scope">
            <span>{{ format(scope.row.durationTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="成绩" width="160" >
          <template #default="scope">
            <span>{{ scope.row.complexScore }}</span>分
          </template>
        </el-table-column>
        <el-table-column  label="" align="right">
          <template v-slot="scope">
            <span class="see_result" @click="seeResult(scope.row)">查看</span>
          </template>
        </el-table-column>
    </el-table>
  </div>
    <!-- {{list}} -->
  
</div> 
</template>

<script>
// import firstIcon from '@assets/images/avatar.png';
import Menu from '@/components/Menu'
export default {
  components:{
    Menu,
 },
  setup(){
    const format = (value) => {
      var s = parseInt(value);
      var m = 0;
      var h = 0;
      if(s >= 60) {
        m = parseInt(s / 60);
        s = parseInt(s % 60);
        if (m > 60) {
          h = parseInt(m / 60);
          m = parseInt(m % 60);
        }
      }
      var result = '' + parseInt(s) + '秒';
      if (m > 0) {
        result = '' + parseInt(m) + '分' + result;
      }
      if (h > 0) {
        result = '' + parseInt(h) + '时' + result
      }
      return result;
    }
    return {
      format
    }
  },
  data() {
    return {
      first:require("@/assets/images/icon_first.png"),
      second:require("@/assets/images/icon_second.png"),
      third:require("@/assets/images/icon_third.png"),
      selectedIndex:0,
      list:[]
    };
  },
  watch: {
    
  },
  methods: {
    zjLogin() {
      if (!sessionStorage.getItem("_token")) {
        this.$http.post("/corporate/university/ai/train/zhujian/login")
          .then((res) => {
            const data = res.data.data;
            sessionStorage.setItem("_token", data.token);
            data.info.tenantId = data.tenantId
            sessionStorage.setItem(
              "zjloginInfo",
              JSON.stringify(data.info)
            );
          });
      } 
    },
    seeResult(parme) {
      parme.isList = 'isList'
      this.$router.push({ path: "/practicePartner", query:parme });
    },
    goReport(item){
      item.isList = true
      this.$router.push({path:'/practicePartner',query:item})
    },
    getList(){
      this.$http.post('/corporate/university/grade/ai/aiDetail',{
        "aiId": this.$route.query.aiId,
        "trainCount": this.$route.query.trainCount,
        "userId": this.$route.query.userId,
        "gradeId":this.$route.query.gradeId
      }).then((res)=>{
        console.log(res)
        if(res.data.code == 0){
          this.list = res.data.data
        }
      }).catch((res)=>{
        this.$message.warn(res.data.msg)
      })
    },
  },
  created() {
    this.zjLogin()
    this.title = localStorage.getItem('testName')
    this.getList()
    // this.gradeConfId = this.$route.query.id || JSON.parse(sessionStorage.getItem('gradeConfInfo')).id
    // this.dayList = data.data
  }
}
</script>

<style lang="scss" scope>
.v-table {
  padding-top: 120px;
  background: #fff;
  width: 1200px;
  margin: auto;
  .el-table{
    th{
      background-color: #fafafa;
      
      .cell{
        color: #999;
      }
    }
    .cell{
        font-size: 16px;
        padding-left: 31px;
        color: #333;
    }
    td{
        height: 80px;
        .cell{
          div{
            font-size: 16px;
            color: #333;
          }
        }
    }
    td:nth-of-type(1){
      border-left: 1px solid #DDDDDD;
    }
    td:last-of-type{
      border-right: 1px solid #DDDDDD;
    }
  }
  .icon-img{
    width: 36px;
    height: 45px;
    display: block;
  }
  .bage{
    width: 32px;
    height: 32px;
    border-radius: 32px;
    text-align: center;
    line-height: 32px;
    color: #999;
    background-color: #EDECED;
  }
}
.see_result{
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #2d70f8;
  margin-right: 10px;
}
</style>